var noScroll;

jQuery(document).ready(function($){
	//No scroll
	var noScroll = function (a) {
		function noScrollOn() {
			document.body.style.top = "-"+$(window).scrollTop()+"px";
			$("html").addClass('no-scroll');
		}
		
		function noScrollOff() {
			var top = document.body.style.top;
			$("html").removeClass('no-scroll');
			document.body.style.top = '';
			window.scrollTo(0, parseInt(top || '0') * -1);
		}
		
		if(a==null) {
			if($('html').hasClass('no-scroll')) {
				noScrollOff();
			} else {
				noScrollOn();
			}
		} else if (a=="on") {
			noScrollOn();
		} else if (a=="off" ) {
			noScrollOff();
		}
	}

	var swiper1 = new Swiper('.section1-swiper', {
		speed: 400,
		// Navigation arrows
		navigation: {
			nextEl: '.section1-swiper-button-next',
			prevEl: '.section1-swiper-button-prev',
		},
	});

	var swiper2 = new Swiper('.section2-swiper', {
		speed: 400,
		// Navigation arrows
		navigation: {
			nextEl: '.section2-swiper-button-next',
			prevEl: '.section2-swiper-button-prev',
		},
	});
});

window.onload = function() {
	$('body').addClass('ready');
}